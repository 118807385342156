.react-date-picker__wrapper {
    border: none !important;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, .1);
}
.react-date-picker__inputGroup > input {
    pointer-events: none;
}
