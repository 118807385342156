.header-button {
    color: #3E3E3E;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    background-color: white;
    border-color: transparent;
    padding: 10px 25px 10px 25px;
    box-shadow: 2px 2px 5px 2px #00000022;
}

.header-button-disabled {
    color: #3E3E3E;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    cursor: not-allowed;
    background-color: white;
    opacity: .7;
    border-color: transparent;
    padding: 10px 25px 10px 25px;
}
