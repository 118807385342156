.default-container {
    width: 94%;
    margin: 155px 3% 3% 3%;
}

.hollow-background {
    background: #F2F2F2;
    /*box-shadow:  3px 3px 10px #d5d5d5,*/
    /*-3px -3px 10px #ffffff;*/
}

.input {
    color: #3E3E3E;
    outline: none;
    font-size: 17px;
    margin-top: 10px;
    border-radius: 5px;
    border-color: transparent;
    padding: 10px;
    box-sizing: border-box;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.burgundy {
    color: #CD004B;
}

.red {
    color: #BC1E2C;
}

.black {
    color: #000000;
}
.white {
    color: #ffffff;
}

.yellow {
    color: #FAA60E;
}

.orange {
    color: #F78B14;
}

.orange-brown {
    color: #DBA246;
}

.green {
    color: #28CB80;
}

.grey {
    color: #707070;
}

/*
tbody {
    display: block;
    flex-grow: 1;
    max-height: 700px;
    overflow: auto;
}

thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
 */
