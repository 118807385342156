.cell {
    padding: 10px 0 10px 10px;
}

.cell-event-title-pic {
    width: 100px;
    height: 100px;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
    object-fit: cover;
}

.cell-profile-pic {
    width: 50px;
    height: 50px;
    cursor: pointer;
    object-fit: cover;
    margin-right: 5px;
    border-radius: 25px;
}

.cell-profile-new-user {
    color: #28CB80;
    font-size: 13px;
    font-weight: bold;
    margin-left: 15px;
}

.cell-event-status-full {
    width: 100px;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border-color: #28CB80;
    justify-content: center;
    background-color: #28CB80;
}

.cell-event-status-open {
    width: 100px;
    padding: 10px;
    border: solid;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
}

.cell-event-status-text {
    max-width: 80px;
    font-size: 17px;
    text-align: center;
}

.cell-event-date {
    font-size: 17px;
}

.cell-event-address {
    font-size: 17px;
}

.cell-event-tickets {
    font-size: 27px;
}

.cell-event-total-places {
    margin-left: 5px;
    font-size: 27px;
}

.cell-event-remaining-tickets {
    font-size: 17px;
}

.cell-event-title {
    font-weight: bold;
    max-width: 125px;
    margin-left: 15px;
}

.cell-profile-name {
    margin-left: 15px;
}

.cell-row-content {
    display: flex;
    align-items: center;
}

.cell-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 12px;
}

.cell-medium-icon {
    width: 22px;
    height: 22px;
    cursor: pointer;
    margin-right: 12px;
}

.cell-small-icon {
    width: 15px;
    height: 15px;
    margin-right: 12px;
    cursor: pointer;
}

.cell-small-icon-disabled {
    width: 15px;
    height: 15px;
    cursor: not-allowed;
    margin-right: 12px;
}

.cell-icon-disabled {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.cell-link {
    width: 50%;
    padding: 5px;
    min-width: 108px;
    border-radius: 20px;
    border: 1px solid black;
    justify-content: center;
}

.cell-link-green-border {
    border-width: 2px;
    border-color: #28CB80;
}

.cell-link-orange-border {
    border-width: 2px;
    border-color: #F78B14;
}

.cell-link-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.column-header-container {
    text-align: start;
    padding-left: 10px;
    padding-bottom: 30px;
}

.column-header-content {
    display: flex;
    align-items: center;
}

.column-header-title {
    font-size: 17px;
    font-weight: bolder;
    cursor: default;
}
