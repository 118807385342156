.user-form-label {
    font-size: 17px;
    margin-top: 40px;
    margin-bottom: 10px;
    font-weight: bold;
}

.user-form-value {
    color: #3E3E3E;
    font-size: 17px;
    margin-top: 5px;
}

.user-form-value-email {
    color: #008AD1;
    cursor: pointer;
    font-size: 17px;
    margin-top: 5px;
}

.user-form-edit-label {
    font-size: 17px;
    margin-top: 40px;
    margin-left: 10px;
    font-weight: bold;
}
