.tooltip-container {
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    background-color: #dfdfdf;
}

.tooltip-label {
    font-size: 14px;
    line-height: 25px;
}
