.calendar {
  width: 200px;
}
.calendarPopup {
  width: 300px;
  border-radius: 5px;
  border-width: 0 !important;
  background-color: white;
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, .1);
  margin-bottom: 10px;
}
