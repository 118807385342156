.no-resource-container {
    width: 100%;
    height: 75vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.action-buttons-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
.action-button-container {
    width: 270px;
    margin-top: 10px;
}

.action-button {
    width: 100%;
    height: 60px;
    color: #3E3E3E;
    cursor: pointer;
    font-size: 17px;
    margin-top: 40px;
    font-weight: bold;
    border-radius: 45px;
    background-color: white;
    border-color: transparent;
    padding: 15px 55px 15px 55px;
}

.no-resource-text {
    width: 50%;
    font-size: 28px;
    text-align: center;
}

.find-resource-text {
    max-width: 50%;
    font-size: 20px;
    margin-top: 40px;
    text-align: center;
}
