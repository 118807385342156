.stripe-button {
    border-radius: 25px;
    background-color: white;
    color: #131313;
    font-weight: bold;
    font-size: 1rem;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 20px;
}
.stripe-activated {
    margin-left: 20px;
    color: #00D050;
}

.group-line {
    display: flex;
    align-items: center;
    padding: 10px;
}
.group-line:nth-child(even) {
    background-color: #dedede;
}
.group-logo {
    height: 60px;
    aspect-ratio: 1;
    border-radius: 5px;
    margin-right: 10px;
    object-fit: cover;
}
